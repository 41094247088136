import React from "react"

const Location = ({
  address,
  address1,
  name,
  distance,
  city,
  state,
  country,
  zip,
  url,
  setDirection,
  latitude,
  longitude,
  myLatitude,
  myLongitude,
  ...props
}) => {
  return (
    <div
      className="location"
      {...props}
    >
      {url ? (
        <a
          href={url}
          target="_blank"
          className="location-name"
          dangerouslySetInnerHTML={{__html: name}}
        ></a>
      ) : (
        <p
          className="location-name"
          dangerouslySetInnerHTML={{__html: name}}
        ></p>
      )}

      <div className="location-direction">
        {address && (
          <p className="location-street">{address}</p>
        )}
        {address1 && (
          <p className="location-street">{address1}</p>
        )}
        <p>{`${city} ${state} ${zip}`}</p>
        <p className="location-country">{country}</p>
      </div>
      {distance && <p className="location-distant">{distance} km</p>}
      <a href={`https://www.google.com/maps/dir/?api=1&origin=${myLatitude},${myLongitude}&destination=${latitude},${longitude}`} target="_blank">Directions</a>
    </div>
  )
}

export default Location