import { gql } from "@apollo/client";

export const GET_STORE_LOCATION = gql`
query GET_STORE_LOCATION($input: RootQueryToBackaStoreConnectionWhereArgs){
    backaStores(where: $input,first:1000) {
      edges {
        node {
          status
          name
          acfBackaStore {
            location {
              address
              address2
              city
              state
              zipCode
              country
              latitude
              longitude
            }
            openingHours {
              monday {
                open
                close
              }
              tuesday {
                open
                close
              }
              wednesday {
                open
                close
              }
              thursday {
                open
                close
              }
              friday {
                open
                close
              }
              saturday {
                open
                close
              }
              sunday {
                open
                close
              }
            }
            additionalInformation {
              tel
              fax
              email
              url
            }
          }
        }
      }
    }
  }
`