import { useLazyQuery } from "@apollo/client"
import { useMemo } from "react"
import { GET_STORE_LOCATION } from "../graphql/queries/storeLocation"
import { haversine } from "../utils/function"

export const useGetStoreLocation = () => {
    const [getStoreLocation, { data, loading }] = useLazyQuery(GET_STORE_LOCATION)
    const getDistance = (latitude, longitude, myLatitude, myLongitude) => {
        if (latitude && longitude && myLatitude && myLongitude) {
            return haversine(myLatitude, myLongitude, latitude, longitude)
        } else {
            return haversine(-28.016667, 153.400000, latitude, longitude)
        }
    }

    return {
        storeData: data?.backaStores?.edges || [],
        getStoreLocation: getStoreLocation,
        loadingStore: loading,
        getDistance:getDistance
    }
}