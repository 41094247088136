import React, { useState, useEffect, useRef } from "react"
import Map from "./Map.component"
import Sidebar from "./Sidebar.component"
import Search from "./Search.component"
import "./StoreFilter.scss"
import { storeContext } from "./context"
import { useGetStoreLocation } from "../../hooks/useGetStoreLocation"
import { getCurrentLocation } from "../../utils/function"
import Loading from "../Loading"
const StoreFilter = () => {
  const radiusList = [10, 25, 50, 100, 200, 500, 1000]
  const numOfResult = [25, 50, 75, 100]
  const [myLocation, setMyLocation] = useState({
    latitude: -28.016667,
    longitude: 153.4
  })
  const [radius, setRadius] = useState(radiusList[6])
  const { storeData, getStoreLocation, getDistance, loadingStore } = useGetStoreLocation()
  const [listDataStore, setListDataStore] = useState([])
  const [popupShow, setPopupShow] = useState(false)
  const [itemHover, setItemHover] = useState(false)
  const [center,setCenter] = useState()
  const [resultNum, setResultNum] = useState(numOfResult[3])
  const getLocation = async () => {
    try {
      const location = await getCurrentLocation()
      setMyLocation(location)
      setCenter(location)
    } catch (error) {
      setMyLocation({
        latitude: -28.016667,
        longitude: 153.4
      })
      setCenter({
        latitude: -28.016667,
        longitude: 153.4
      })
    }
  }
  const onSearchLocation = () => {
    if (myLocation) {
      setCenter(myLocation)
      const temp = storeData
        ?.filter(
          item =>
            getDistance(
              Number(item.node.acfBackaStore.location.latitude),
              Number(item.node.acfBackaStore.location.longitude),
              myLocation?.latitude,
              myLocation?.longitude
            ) <= radius
        )
        .slice(0, resultNum)
      setListDataStore(temp)
    } else {
      setListDataStore(storeData)
    }
  }
  useEffect(() => {
    getLocation()
    getStoreLocation({
      variables: {
        input: { status: "PUBLISH" }
      },
    }).then(({data}) => {
      const temp = data?.backaStores?.edges
        ?.filter(
          item =>
            getDistance(
              Number(item.node.acfBackaStore.location.latitude),
              Number(item.node.acfBackaStore.location.longitude),
              myLocation?.latitude,
              myLocation?.longitude
            ) <= radius
        )
        .slice(0, resultNum)
      setListDataStore(temp);
    });
  }, [])

  return (
    <div className="store-filter">
      {loadingStore && <Loading />}
      <storeContext.Provider
        value={{
          myLocation,
          location: listDataStore,
          loading: loadingStore,
          popupShow,
          setPopupShow,
          itemHover,
          setItemHover
        }}
      >
        <Search
          resultNum={resultNum}
          radius={radius}
          searchInput={myLocation}
          radiusList={radiusList}
          numOfResult={numOfResult}
          setRadius={setRadius}
          setSearchInput={setMyLocation}
          setResultNum={setResultNum}
          onSearchLocation={onSearchLocation}
        />
        <div className="store-filter-body">
          <div className="store-sidebar" style={{ height: "350px" }}>
            <Sidebar />
          </div>
          <div className="store-map" style={{ height: "350px" }}>
            <Map radius={radius} center={center}/>
          </div>
        </div>
      </storeContext.Provider>
    </div>
  )
}

export default StoreFilter
