// Step 1: Import React
import * as React from "react"
import Layout from "../../components/layout"
import Heading from "../../components/Heading"
import StoreFilter from "../../components/StoreFilter"
import SubBanner from "../../components/SubBanner"
import subbanner from "../../assets/images/BG-Banner-Contact.png"
import "./find-a-retailer.scss"
import Seo from "../../components/seo"

const FindARetailer = () => {
  const seo = {
    title:"Find A Retailer - Backa Australia",
    description:""
  }
  return (
    <Layout
      pageClass="page-find-a-retailer"
    >
      <Seo title={seo.title} description={seo.description}/>
      <SubBanner
        src={subbanner}
      />
      <Heading
        color = "#0a7611"
        title = "Our Locations"
        supText = "WE LOVE OUR CUSTOMERS"
      >
        <p>We built an online store to make ordering easy,&nbsp;
          but we’d still love to see you in person.&nbsp;
          Use the handy Store Finder below to discover&nbsp;
          where you can find great Backa products in your local area.</p>
      </Heading>
      <div className="container">
        <StoreFilter />
      </div>
    </Layout>
  )
}

export default FindARetailer