import React from "react"
import "./heading.scss"

const Heading = ({
  className,
  color,
  title,
  supText,
  children,
  description
}) => {
  return (
    <div className={`heading-section ${className ? className : ""}`}>
      {supText && <h4 className="container">{supText}</h4>}
      <div className='slashed-background'>
        <div className='heading-title'>
          <h2 style={{ color: color }}>{title}</h2>
        </div>
      </div>
      {children && <div className='description'>
        {children}
      </div>}
    </div>
  )
}

export default Heading
