import React, { useContext, useState } from "react"
import GoogleMapReact from "google-map-react"
import { storeContext } from "./context"
import { useEffect } from "react"
import Marker from "../../assets/images/dark-green.png"
import StartMarker from "../../assets/images/start-marker.png"
const Popup = ({
  address,
  address1,
  name,
  city,
  state,
  phone,
  zip,
  url,
  onClose,
  myLocation,
  latitude,
  longitude
}) => {
  return (
    <div className="popup-map">
      <button
        className="popup-map-close"
        onClick={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <img
          // eslint-disable-next-line max-len
          src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E"
          alt="close"
        />
      </button>
      <div className="popup-map-inner">
        <div>
          {url ? (
            <a target="_blank" href={url}>
              <strong dangerouslySetInnerHTML={{ __html: name }}></strong>
            </a>
          ) : (
            <p>
              <strong dangerouslySetInnerHTML={{ __html: name }}></strong>
            </p>
          )}

          {address && <p>{address}</p>}
          {address1 && <p>{address1}</p>}
          <p>{`${city} ${state} ${zip}`}</p>
          <p className="popup-info-phone">
            <strong>Phone</strong>: <a href={`tel:${phone}`}>{phone}</a>
          </p>
          <div className="popup-info-actions">
            <a
              className="popup-directions"
              target="_blank"
              // eslint-disable-next-line max-len
              href={`https://www.google.com/maps/dir/?api=1&origin=${myLocation.latitude},${myLocation.longitude}&destination=${latitude},${longitude}`}
            >
              Directions
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
const Map = props => {
  const { center } = props
  const { location: locationList, popupShow, setPopupShow, itemHover } = useContext(storeContext)

  return (
    <div style={{ height: "350px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDuI0WZWPETdDbud9vCg-mv1GjyAYpmz30", libraries: ["places"] }}
        hoverDistance={30}
        zoom={8}
        center={[center?.latitude || -28.016667, center?.longitude || 153.4]}
      >
        {locationList.map((item, index) => {
          const { location, additionalInformation } = item?.node?.acfBackaStore
          const { name } = item?.node
          return (
            <div
              key={index}
              lat={Number(location.latitude)}
              lng={Number(location.longitude)}
              style={{
                zIndex: index === popupShow || (!popupShow && itemHover === index) ? 10 : 1
              }}
              className={`icon-mark ${
                !popupShow && itemHover === index ? "icon-mark-animate" : ""
              }`}
            >
              {index === popupShow && (
                <Popup
                  address={location.address}
                  address1={location.address1}
                  name={name}
                  city={location.city}
                  state={location.state}
                  phone={additionalInformation.tel}
                  zip={location.zipCode}
                  url={additionalInformation.url}
                  myLocation={center ? center : { latitude: -28.016667, longitude: 153.4 }}
                  latitude={location.latitude}
                  longitude={location.longitude}
                  onClose={() => setPopupShow(false)}
                />
              )}
              <img
                // eslint-disable-next-line max-len
                src={Marker}
                alt=""
                onClick={() => setPopupShow(index)}
              />
            </div>
          )
        })}
        <div
          lat={Number(center?.latitude) || -28.016667}
          lng={Number(center?.longitude) || 153.4}
          style={{ zIndex: 1 }}
          className={`icon-mark `}
        >
          <img
            // eslint-disable-next-line max-len
            src={StartMarker}
            alt=""
          />
        </div>
      </GoogleMapReact>
    </div>
  )
}

export default Map
