import React from "react"
import { Link } from "gatsby"
import "./SubBanner.scss"
const SubBanner = ({
  to,
  children,
  src,
  ...props
}) => {
  return to ? (
    <Link
      to={to}
      className="sub-banner">
        <img src={src} alt="sub-banner" />
    </Link>
  ) : (
    <div
      className="sub-banner">
        <img src={src} alt="sub-banner" />
    </div>
  )
}

export default SubBanner