import React, { useContext } from "react"
import Location from "./Location.component"
import { storeContext } from "./context"
import { haversine } from "../../utils/function"
import LoadGif from "../../assets/images/ajax-loader.gif"

const Sidebar = () => {
  const { location, loading, setItemHover,myLocation } = useContext(storeContext)
  const getDistance = (latitude,longitude,myLatitude,myLongitude) => {
    if(latitude && longitude && myLatitude && myLongitude) {
      return haversine(myLatitude, myLongitude, latitude, longitude)
    }else {
      return haversine(-28.016667, 153.400000, latitude, longitude)
    }
  }
  if (loading) {
    return (
      <div className="location-preloader">
        <img
          src={LoadGif}
        />Searching...
      </div>
    )
  }
  return (
    <div >
      {location.length === 0 && <p>No results found</p>}
      {location.map((data, index) => {
        const {name} = data?.node
        const {additionalInformation, location,openingHours} = data?.node?.acfBackaStore
        return (
        <Location
          address={location.address}
          address1={location.address1}
          name={name}
          distance={getDistance(Number(location.latitude), Number(location.longitude), myLocation?.latitude, myLocation?.longitude)}
          city={location.city}
          state={location.state}
          country={location.country}
          zip={location.zipCode}
          url={additionalInformation.url}
          latitude={location.latitude}
          longitude={location.longitude}
          myLatitude={myLocation?.latitude ? myLocation?.latitude : -28.016667}
          myLongitude={myLocation?.longitude ? myLocation?.longitude : 153.400000}
          key={index}
          onMouseEnter={() => {
            setItemHover(index)
          }}

          onMouseLeave={() => {
            setItemHover((prev) => {
              if (prev === index) {
                return false
              }
              return prev
            })
          }}
        />
      )})}
    </div>
  )
}

export default Sidebar