import React, { useEffect } from "react"
import Autocomplete from "react-google-autocomplete"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
const Search = props => {
  const {
    radiusList,
    numOfResult,
    setRadius,
    setSearchInput,
    setResultNum,
    onSearchLocation,
    radius,
    resultNum
  } = props
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: "AIzaSyDuI0WZWPETdDbud9vCg-mv1GjyAYpmz30",
    options: {
      types: [
        "postal_code",
        "administrative_area_level_1",
        "administrative_area_level_2",
        "street_address",
        "route",
      ],
      componentRestrictions: { country: "au" }
    }
  })
  const onSuggestionSelected = place => {
    const { geometry } = place
    if (geometry) {
      setSearchInput({ latitude: geometry.location.lat(), longitude: geometry.location.lng() })
    }
  }
  const handleChange = evt => {
    getPlacePredictions({ input: evt.target.value })
  }
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService?.getDetails(
        {
          ...placePredictions[0]
        },
        placeDetails => {
          const { geometry } = placeDetails
          if (geometry) {
            setSearchInput({
              latitude: geometry.location.lat(),
              longitude: geometry.location.lng()
            })
          }
        }
      )
  }, [placePredictions])
  return (
    <div className="store-filter-search">
      <div className="search-input">
        <label>Your location</label>
        <Autocomplete
          apiKey="AIzaSyDuI0WZWPETdDbud9vCg-mv1GjyAYpmz30"
          onPlaceSelected={onSuggestionSelected}
          onChange={handleChange}
          options={{
            types: [
              "postal_code",
              "administrative_area_level_1",
              "administrative_area_level_2",
              "street_address",
              "route",
            ],
            componentRestrictions: { country: "au" }
          }}
        />
      </div>
      <div className="wrap-right-content">
        <div className="search-select">
          <div className="input-wrap">
            <label>Search radius</label>
            <select name="" id="" onChange={e => setRadius(Number(e.target.value))} value={radius}>
              {radiusList.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}km
                  </option>
                )
              })}
            </select>
          </div>
          <div className="input-wrap">
            <label>Result</label>
            <select
              name=""
              id=""
              onChange={e => setResultNum(Number(e.target.value))}
              value={resultNum}
            >
              {numOfResult.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {item}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <button className="search-submit" type="button" onClick={onSearchLocation}>
          Search
        </button>
      </div>
    </div>
  )
}

export default Search
